<ul *ngIf="!appService.isMobile" nz-menu nzTheme="dark"  nzMode="inline" [nzInlineCollapsed]="authService.isCollapsed">
    <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: ActiveMenu } " ></ng-container>
    <ng-template #menuTpl let-ActiveMenu >
      <ng-container *ngFor="let menu of ActiveMenu" >
        <li 
          *ngIf="!menu.children"
          nz-menu-item
  
          [nzDisabled]="menu.disabled"
          [nzSelected]="menu.selected"
        > <a routerLink={{menu.link}}  ><i nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i>
          <span>{{menu.title |translate}}</span></a>
       
        </li>
        <li
          *ngIf="menu.children"
          nz-submenu
         
          nzOpen="false"
          [nzTitle]="menu.title |translate"
          [nzIcon]="menu.icon"
          [nzDisabled]="menu.disabled"
        >
          <ul>
            <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"></ng-container>
          </ul>
        </li>
      </ng-container>
    </ng-template>
  </ul>
  
  
  <!-- <ul *ngIf="appService.isMobile && AuthService.isVisible" nz-menu nzTheme="dark" nzMode="inline" style="width: 260px;">
    <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: ActiveMenu }"></ng-container>
    <ng-template #menuTpl let-ActiveMenu>
      <ng-container *ngFor="let menu of ActiveMenu">
        <li
          *ngIf="!menu.children"
          nz-menu-item
          [nzPaddingLeft]="menu.level * 12"
          [nzDisabled]="menu.disabled"
          [nzSelected]="menu.selected"
          (click)="AuthService.isVisible=false"
        >
        <a routerLink={{menu.link}}><i nz-icon [nzType]="menu.icon" (click)="AuthService.isVisible=false" *ngIf="menu.icon"></i>
          <span>{{langService.LocalResource(menu.title)}}</span></a>
        </li>
        <li
          *ngIf="menu.children"
          nz-submenu
          [nzPaddingLeft]="menu.level * 12"
          nzOpen="false"
          [nzTitle]="langService.LocalResource(menu.title)"
          [nzIcon]="menu.icon"
          [nzDisabled]="menu.disabled"
        >
          <ul>
            <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"></ng-container>
          </ul>
        </li>
      </ng-container>
    </ng-template>
  </ul> -->