import { Injectable } from '@angular/core';


declare var custom:any;
declare var slider:any;

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  activeLink=1;

  public isMobile=false;
  public isTablet=false;
  public isDesktop=false;
  constructor() { }

  initCustom()
  {
   slider.init();
   this.gotoTop();
  }
  initSlider()
  {
   custom.init();
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
    
    });
  }
}
