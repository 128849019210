
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import {  MessageService } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../_services/layout.service';
import { MessagesModule } from 'primeng/messages';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  standalone: true,
  providers: [MessageService],
  imports: [TranslateModule,MessagesModule]

})
export class BreadcrumbComponent implements OnInit {

  public deliminator: string = '>';
  subscription: Subscription = new Subscription();

  breadcrumbs!: Array<{ label: string; url: string }>;
  BCTabTitle = '';

  
  constructor(private layoutService: LayoutService, private messageService: MessageService,
  private translate: TranslateService,
    private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {


    this.subscription.add(
      this.layoutService.getTabTitle.subscribe(
        (TabTitle) => (this.BCTabTitle = TabTitle.title)
      )
    );

    this.subscription.add(
      this.layoutService.getnewMsgValue.subscribe(
        (newMsgValue) => (this.showMsg(newMsgValue))
      )
    );



    // this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe(event => {
    //     this.breadcrumbs = [];
    //     let currentRoute = this.activatedRoute.root,
    //       url = '';
    //     do {
    //       const childrenRoutes = currentRoute.children;
    //       childrenRoutes.forEach(route => {
    //         if (route.outlet === 'primary') {
    //           const routeSnapshot = route.snapshot;

    //           url +=
    //             '/' + routeSnapshot.url.map(segment => segment.path).join('/');
    //           this.breadcrumbs.push({
    //             label: route.snapshot.data['breadCrum'],
    //             url: url
    //           });

    //           currentRoute = route;
    //         }
    //       });
    //     } while (currentRoute);
    //   });
  }
  showMsg(newMsgValue:any) {
    this.messageService.clear();
    this.messageService.add(newMsgValue)
  }
}
