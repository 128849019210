

  <nz-header>
    <div class="app-header">
      
        <nav class="navbar navbar-expand navbar-dark bg-color1 justify-content-between" >
          <div >
            
                <span class="header-trigger desktop-only" (click)="AuthService.isCollapsed = !AuthService.isCollapsed">
                    <i class="trigger"
                       nz-icon
                       [nzType]="AuthService.isCollapsed ? 'menu-fold' : 'menu-unfold'"
                    ></i>
                </span>
           
  
          </div>
    
          <div  >
            <div class="navbar-nav " >
          <!-- chatNotifications -->
    
                <a class="nav-item nav-link">
                  <nz-badge [nzStyle]="{ backgroundColor: '#87d068',boxShadow: '0 0 0 1px #87d068 inset'}" [nzCount]="_ChatNotifications.length">
                    <a nz-dropdown  [nzDropdownMenu]="menu2">
                       <nz-avatar nzIcon="mail" style="background-color:transparent;font-size: 21px;"></nz-avatar>
                     </a>
                      <nz-dropdown-menu #menu2="nzDropdownMenu" >
                        <div style="overflow-y: auto;max-height: 380px;margin-top: 16px;" *ngIf="_ChatNotifications.length>0">
                        <nz-card  *ngFor="let noti of _ChatNotifications"   class="notif" >
                         <ng-template #avatarTemplatem>
                           <nz-avatar [nzSrc]="env+noti.createdByPicture"></nz-avatar>
                         </ng-template>
                           <nz-card-meta
                             [nzAvatar]="avatarTemplatem"
                             [nzTitle]="noti.createdByName"
                             [nzDescription]= "noti.message"
                              (click)="startCaseChat(noti)"
                            
                           ></nz-card-meta>
                           <p class="timeAgo"><small>{{noti.createdAt | timeAgo}}</small></p>
                           <i (click)="deleteChatNoti(noti.id)" class="iclose" nz-icon nzType="close"></i>
                       </nz-card> 
    
  
                 </div>
            
    
              <nz-card   class="notif" *ngIf="_ChatNotifications.length>0 ">
                <nz-card-meta style="text-align: center"
    
                  nzDescription= "مسح جميع الاشعارات"
                   (click)="deleteAllChatNoti()"
                 
                ></nz-card-meta>
              </nz-card> 
                     </nz-dropdown-menu> 
                   
                   </nz-badge>
                </a>             
           
          <!-- Notifications -->
                <a class="nav-item nav-link" >
                  <nz-badge [nzStyle]="{ backgroundColor: '#87d068',boxShadow: '0 0 0 1px #87d068 inset'}" [nzCount]="_Notifications.length">
                   <a nz-dropdown  [nzDropdownMenu]="menu3">
                      <nz-avatar nzIcon="bell" style="background-color:transparent;font-size: 21px;"></nz-avatar>
                    </a>
                     <nz-dropdown-menu   #menu3="nzDropdownMenu" >
                       <div style="overflow-y: auto;max-height: 380px;margin-top: 16px;" *ngIf="_Notifications.length>0">
                       <nz-card  *ngFor="let noti of _Notifications"  class="notif" >
                        <ng-template #avatarTemplatem>
                          <nz-avatar [nzSrc]="env+noti.createdByPicture"></nz-avatar>
                        </ng-template>
                          <nz-card-meta *ngIf="!noti.link"
                            [nzAvatar]="avatarTemplatem"
                            [nzTitle]="noti.createdByName"
                            [nzDescription]= "noti.title"
                           
                          ></nz-card-meta>
                          <nz-card-meta *ngIf="noti.link"
                          [nzAvatar]="avatarTemplatem"
                          [nzTitle]="noti.createdByName"
                          [nzDescription]= "noti.title"
                          [routerLink]="[noti.link, noti.param]"
                         
                        ></nz-card-meta>
                          <p class="timeAgo"><small>{{noti.createdAt | timeAgo}}</small></p>
                          <i (click)="deleteNoti(noti.id)"  class="iclose" nz-icon nzType="close"></i>
                      </nz-card> 
                </div>
    
                <nz-card   class="notif" *ngIf="_Notifications.length>0">
                  <nz-card-meta style="text-align: center"
    
                    nzDescription= "Delete All"
                     (click)="deleteAllNoti()"
                   
                  ></nz-card-meta>
              </nz-card> 
                    </nz-dropdown-menu> 
                  
                  </nz-badge>
                </a>

                  <!-- Lang -->
              <a nz-dropdown class="nav-item nav-link" [nzDropdownMenu]="menu1" style="padding-left: 15px;padding-right: 15px;">
                <nz-avatar nzIcon="global" style="background-color:transparent;font-size: 21px;"></nz-avatar>
              </a>
              <nz-dropdown-menu #menu1="nzDropdownMenu">
                  <ul nz-menu style="width: 150px;margin-top: 16px" >
                    <li nz-menu-item style="cursor:pointer;" (click)="changeLang('en')">
                      <i nz-icon nzType="global"></i>&nbsp;English</li>
                      <li nz-menu-item style="cursor:pointer;" (click)="changeLang('ar')">
                      <i nz-icon nzType="global"></i>&nbsp;العربية</li>
                  </ul>
              </nz-dropdown-menu>
    
    <!-- User Icon -->
              <a nz-dropdown class="nav-item nav-link" [nzDropdownMenu]="Usermenu">
                <nz-avatar nzIcon="user" style="background-color:rgb(60, 158, 150);"></nz-avatar>
              </a>
              <nz-dropdown-menu #Usermenu="nzDropdownMenu">
                <nz-card style="width: 300px;margin-top: 16px" [nzActions]="[actionSetting, actionEdit,actionEllipsis]">
                  <nz-skeleton [nzActive]="true" [nzLoading]="false"  [nzAvatar]="{ size: 'large' }">
                    <nz-card-meta
                      [nzAvatar]="avatarTemplate"
                      [nzTitle]="userInfo.firstName"
                      [nzDescription]="userInfo.email"
                    ></nz-card-meta>
                  </nz-skeleton>
                </nz-card>
                <ng-template #avatarTemplate>
                  <nz-avatar [nzSrc]="userInfo.picture"></nz-avatar>
                </ng-template>
                <ng-template #avatar2Template>
                  <nz-avatar nzIcon="global" style="color:#f56a00; background-color:transparent;font-size: 22px;"></nz-avatar>
                </ng-template>
                <ng-template #actionSetting>
                  <i nz-icon nzType="setting" (click)="changePassword()"></i>
                </ng-template>
                <ng-template #actionEdit>
                  <i nz-icon nzType="edit"></i>
                </ng-template>
                <ng-template #actionEllipsis>
           
                  <i nz-icon nzType="logout" nzTheme="outline" (click)="logout()"></i>
                </ng-template>
    
                <ng-template #actionEdit>
           
                  <i nz-icon nzType="edit" nzTheme="outline" (click)="editAccount()"></i>
                </ng-template>
    
              </nz-dropdown-menu>
          </div>
        </div>
    
          
          </nav>
          
    </div>
    
    </nz-header>
    