import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";  // or from "@microsoft/signalr" if you are using a new library
import { AuthService } from './authservice';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public data: any[]=[];

  constructor(public authService:AuthService){}
public hubNotiConnection: signalR.HubConnection| undefined
//public hubChatConnection: signalR.HubConnection

//public hubChatConnection: signalR.HubConnection
private urlNotification = environment.appUrl+'NotificationHub'
//private urlChat = environment.appUrl+'Chat'


  public startConnectionNotification = () => {
    this.hubNotiConnection = new signalR.HubConnectionBuilder()
                            .withUrl(this.urlNotification)
                            .withAutomaticReconnect()
                            .configureLogging(signalR.LogLevel.Information)
                            .build();

    this.hubNotiConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err=> console.log('Error while starting connection: ' + err))
  }

  // public startConnectionChat = async () => {
  //   let token=this.authService.getToken();
  //   this.hubChatConnection = new signalR.HubConnectionBuilder()
  //                           .withUrl(this.urlChat)
  //                           .build();

  //  await this.hubChatConnection
  //     .start()
  //     .then(() => console.log('Connection started'))
  //     .catch(err => console.log('Error while starting connection: ' + err))
  // }

  public addNotificationDataListener = () => {
    this.hubNotiConnection!.on('notification_data', (data) => {
      this.data = data;
      console.log(data);
    });
  }

  // public addChatDataListener = () => {
  //   this.hubChatConnection.on('chat_data', (data) => {
  //     this.data = data;
  //     console.log(data);
  //   });
  // }
}