
<ngx-loading *ngIf="useSpinner"
[show]="loading"
[config]="config"

></ngx-loading>

<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" 
              nzCollapsible 
              nzWidth="256px"
              nzBreakpoint="md"
              [(nzCollapsed)]="authService.isCollapsed"
              [nzTrigger]="null">
      <div class="sidebar-logo">
        <a >
          <img src="assets/images/logo1.png" alt="logo" class="mx-1">
          <h1>ZIU System</h1>
        </a>
      </div>
  
      
      <app-side-bar ></app-side-bar>
    </nz-sider>
   
   
  <nz-layout>

  <app-top-bar></app-top-bar>
  <app-breadcrumb class="mt-4"></app-breadcrumb> 

      <div class="dashboard_padding">
          <router-outlet></router-outlet>
        </div>

  </nz-layout>

  </nz-layout>
  