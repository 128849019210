<p-messages></p-messages>



<div class="BC-container">
    <i class="fa fa-dashboard page-header-icon"></i>
    <div class="mx-2 mt-2">
        {{BCTabTitle |translate}}
    </div>

  </div>